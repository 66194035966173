var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"animated fadeIn"},[_c('b-row',[_c('b-col',{attrs:{"sm":"12","lg":"12"}},[_c('b-card',{staticClass:"bg-gray-100",attrs:{"no-body":""}},[_c('b-card-body',{},[_c('CustomTable',{attrs:{"type":"table","primaryKey":"id","title":"Invoice over Months","class_name":"InvoiceYearMonthData"}})],1)],1)],1),_c('b-col',{attrs:{"sm":"12","lg":"12"}},[_c('b-card',{staticClass:"bg-white",attrs:{"no-body":""}},[_c('b-card-body',{staticClass:"pb-0"},[_c('h4',{staticClass:"mb-0"},[_vm._v("Invoice over Months")])]),_c('CustomChart',{staticClass:"p-3",attrs:{"height":"300","width":"100%","class_name":"InvoiceYearMonthData"}})],1)],1),_c('b-col',{attrs:{"sm":"12","lg":"12"}},[_c('b-card',{staticClass:"bg-white",attrs:{"no-body":""}},[_c('b-card-body',{staticClass:"pb-0"},[_c('h4',{staticClass:"mb-0"},[_vm._v("Total Sales over Months")])]),_c('CustomChart',{staticClass:"p-3",attrs:{"height":"300","width":"100%","class_name":"OrderYearMonthData","chart_configurations":{
            chart_options: {
              scales: {
                x_axes: [{}],
                y_axes: [
                  { id: 'A' },
                  { id: 'B' },
                ],
              },
            },
            x_config: {
              property_key: 'id',
            },
            y_config: [
              {
                property_key: 'count',
                y_axis_ID: 'B',
              },
              {
                property_key: 'sum_total_price',
                y_axis_ID: 'A',
                type: 'bar',
              },
              {
                property_key: 'sum_total_price_without_tax',
                y_axis_ID: 'A',
                type: 'bar',
              },
              {
                property_key: 'sum_total_tax',
                y_axis_ID: 'A',
                type: 'bar',
              },
            ],
          }}})],1)],1),_c('b-col',{attrs:{"sm":"12","lg":"12"}},[_c('b-card',{staticClass:"bg-white",attrs:{"no-body":""}},[_c('b-card-body',{staticClass:"pb-0"},[_c('h4',{staticClass:"mb-0"},[_vm._v("Total Sales over Months")])]),_c('CustomChart',{staticClass:"p-3",attrs:{"height":"300","width":"100%","class_name":"OrderYearMonthData","chart_configurations":{
            chart_options: {
              scales: {
                x_axes: [{}],
                y_axes: [
                  { id: 'A' },
                  { id: 'B' },
                ],
              },
            },
            x_config: {
              property_key: 'id',
            },
            y_config: [
              {
                property_key: 'count',
                y_axis_ID: 'B',
              },
              {
                property_key: 'sum_total_price',
                y_axis_ID: 'A',
                type: 'bar',
              },
            ],
          }}})],1)],1),_c('b-col',{attrs:{"sm":"12","lg":"12"}},[_c('b-card',{staticClass:"bg-gray-100",attrs:{"no-body":""}},[_c('b-card-body',{},[_c('CustomTable',{attrs:{"type":"table","primaryKey":"id","title":"Monthly Sales","class_name":"OrderYearMonthData","fieldSettings":[
              {
                property_key: 'id',
                title: 'Month',
              },
              {
                property_key: 'count',
                title: 'Total',
              },
              {
                property_key: 'sum_total_price_without_tax',
                title: 'Total Price Without Tax',
              },
              {
                property_key: 'sum_total_tax',
                title: 'Total Tax',
              },
              {
                property_key: 'sum_total_price',
                title: 'Total Price',
              }
            ]}})],1)],1)],1),_c('b-col',{attrs:{"sm":"12","lg":"12"}},[_c('b-card',{staticClass:"bg-gray-100",attrs:{"no-body":""}},[_c('b-card-body',{},[_c('CustomTable',{attrs:{"type":"table","primaryKey":"id","title":"Delivery Zone","class_name":"DeliveryZone","fieldSettings":[
              {
                property_key: 'name',
                title: 'Name',
              },
              {
                property_key: 'time_taken',
                title: 'Time Taken',
              }
            ]}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }