<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="12" lg="12">
        <b-card no-body class="bg-gray-100">
          <b-card-body class="">
            <CustomTable
              type="table"
              primaryKey="id"
              title="Invoice over Months"
              class_name="InvoiceYearMonthData"
            />
          </b-card-body>
        </b-card>
      </b-col>
      <b-col sm="12" lg="12">
        <b-card no-body class="bg-white">
          <b-card-body class="pb-0">
            <h4 class="mb-0">Invoice over Months</h4>
          </b-card-body>
          <CustomChart
            height="300"
            width="100%"
            class="p-3"
            class_name="InvoiceYearMonthData"
          />
        </b-card>
      </b-col>
      <b-col sm="12" lg="12">
        <b-card no-body class="bg-white">
          <b-card-body class="pb-0">
            <h4 class="mb-0">Total Sales over Months</h4>
          </b-card-body>
          <CustomChart
            height="300"
            width="100%"
            class="p-3"
            class_name="OrderYearMonthData"
            :chart_configurations="{
              chart_options: {
                scales: {
                  x_axes: [{}],
                  y_axes: [
                    { id: 'A' },
                    { id: 'B' },
                  ],
                },
              },
              x_config: {
                property_key: 'id',
              },
              y_config: [
                {
                  property_key: 'count',
                  y_axis_ID: 'B',
                },
                {
                  property_key: 'sum_total_price',
                  y_axis_ID: 'A',
                  type: 'bar',
                },
                {
                  property_key: 'sum_total_price_without_tax',
                  y_axis_ID: 'A',
                  type: 'bar',
                },
                {
                  property_key: 'sum_total_tax',
                  y_axis_ID: 'A',
                  type: 'bar',
                },
              ],
            }"
          />
        </b-card>
      </b-col>
      <b-col sm="12" lg="12">
        <b-card no-body class="bg-white">
          <b-card-body class="pb-0">
            <h4 class="mb-0">Total Sales over Months</h4>
          </b-card-body>
          <CustomChart
            height="300"
            width="100%"
            class="p-3"
            class_name="OrderYearMonthData"
            :chart_configurations="{
              chart_options: {
                scales: {
                  x_axes: [{}],
                  y_axes: [
                    { id: 'A' },
                    { id: 'B' },
                  ],
                },
              },
              x_config: {
                property_key: 'id',
              },
              y_config: [
                {
                  property_key: 'count',
                  y_axis_ID: 'B',
                },
                {
                  property_key: 'sum_total_price',
                  y_axis_ID: 'A',
                  type: 'bar',
                },
              ],
            }"
          />
        </b-card>
      </b-col>
      <b-col sm="12" lg="12">
        <b-card no-body class="bg-gray-100">
          <b-card-body class="">
            <CustomTable
              type="table"
              primaryKey="id"
              title="Monthly Sales"
              class_name="OrderYearMonthData"
              :fieldSettings="[
                {
                  property_key: 'id',
                  title: 'Month',
                },
                {
                  property_key: 'count',
                  title: 'Total',
                },
                {
                  property_key: 'sum_total_price_without_tax',
                  title: 'Total Price Without Tax',
                },
                {
                  property_key: 'sum_total_tax',
                  title: 'Total Tax',
                },
                {
                  property_key: 'sum_total_price',
                  title: 'Total Price',
                }
              ]"
            />
          </b-card-body>
        </b-card>
      </b-col>
      <b-col sm="12" lg="12">
        <b-card no-body class="bg-gray-100">
          <b-card-body class="">
            <CustomTable
              type="table"
              primaryKey="id"
              title="Delivery Zone"
              class_name="DeliveryZone"
              :fieldSettings="[
                {
                  property_key: 'name',
                  title: 'Name',
                },
                {
                  property_key: 'time_taken',
                  title: 'Time Taken',
                }
              ]"
            />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import chart_configuration from '../../descriptor/coreui/components/chart/chartConfiguration';
import CustomTable from '../../descriptor/coreui/components/chart/CustomTable';
import CustomChart from '../../descriptor/coreui/components/chart/CustomChart';

export default {
  name: 'dashboard',
  components: {
    CustomTable,
    CustomChart,
  },
  data: function () {
    return {
      selected: 'Month',
      chart_configuration,
    }
  },
  methods: {

  }
}
</script>
