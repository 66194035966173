<script>
import { dataChecking } from '../../globalUtils';
import chart_configuration from './chartConfiguration';

export default {
  props: {
    primaryKey: {
      type: String,
    },
    title: {
      type: String,
    },
    class_name: {
      type: String,
    },
    fieldSettings: {
      type: Array,
    },
  },
  data: () => {
    return {
      dataSet: null,
      loaded_from_server: false,
    };
  },
  computed: {
    property_data() {
      return this.$d.getProperties(this.class_name);
    },
    // classData() {
    //   return this.$d.getClass(this.class_name);
    // },
    _fieldSettings() {
      if (this.fieldSettings) {
        return this.fieldSettings;
      }

      const settings = [];
      const chartConfig = chart_configuration[this.class_name];

      if (chartConfig && chartConfig.x_config) {
        const targetProperty = this.property_data.find((property) => property.property_key === chartConfig.x_config.property_key);
        settings.push({
          property_key: targetProperty.property_key,
          title: targetProperty.property_name,
        });
      }

      if (chartConfig && chartConfig.y_config && chartConfig.y_config.length) {
        chartConfig.y_config.forEach((yConfigItem) => {
          const targetProperty = this.property_data.find((property) => property.property_key === yConfigItem.property_key);
          settings.push({
            property_key: targetProperty.property_key,
            title: targetProperty.property_name,
          });
        });
      }
      return settings;
    },
  },
  created() {
    if (!this.class_name) {
      console.error('class_name for chart not found.');
      return;
    }

    this.$d.request(
      'index',
      this.class_name,
      {
        index_withs: true,
      },
      (dataSet) => {
        this.dataSet = dataSet;
        this.loaded_from_server = true;

        if (this.cache_key) {
          // console.log('save pagination', this.cache_key);
          this.$d.setCache(this.cache_key);
        }

        this.$forceUpdate();
      },
      (error_message) => {
        console.error(error_message);
      }
    );
  },
  methods: {
    dataChecking
  }
}
</script>

<template>
  <div>
    <h5 class="mb-0">{{ title }}</h5>
    <div v-if="!loaded_from_server">Loading...</div>
    <table class="my-custom-table" v-if="loaded_from_server">
      <tr>
        <th
          v-for="(field, index) in _fieldSettings"
          :key="index"
        >
          {{ field.title }}
        </th>
      </tr>
      <tr
        v-for="(row, index) in dataSet"
        :key="index"
      >
        <td
          v-for="(field, index) in _fieldSettings"
          :key="index"
        >
          {{ dataChecking(row, field.property_key)}}
        </td>
      </tr>
    </table>
  </div>
</template>
